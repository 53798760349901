var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showSuccessSignModal)?_c('div',{staticClass:"default-modal"},[_c('div',{staticClass:"default-modal__container"},[_c('img',{staticClass:"default-modal__close",attrs:{"src":require("@/assets/images/close-groups-modal.svg")},on:{"click":_vm.handleCloseSuccessSignModal}}),_c('div',{staticClass:"default-modal__body"},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.modalTitle)}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.modalDescription)}})]),_c('div',{staticClass:"default-modal__footer"},[_c('button',{staticClass:"button-default",on:{"click":_vm.handleCloseSuccessSignModal}},[_vm._v(" Close ")])])])]):_vm._e(),_c('div',{ref:"screen-5",staticClass:"main-page__footer p-4"},[_c('div',{staticClass:"main-page__footer__container"},[_vm._m(0),_c('div',{staticClass:"main-page__footer__item link"},_vm._l((_vm.routes.filter(function (rt) { return rt.name !== 'Logout'; })),function(route){return _c('div',{key:route.name},[(
                (route.navFooter || route.logout) &&
                  (route.isAuthorized
                    ? route.isAuthorized == _vm.isAuthorized
                    : true)
              )?_c('router-link',{staticClass:"main-page__footer__link",attrs:{"to":route.path}},[_vm._v(" "+_vm._s(route.name)+" ")]):_vm._e()],1)}),0),_c('div',{staticClass:"main-page__footer__item main-page__footer__form"},[_vm._m(1),_c('form',{staticClass:"position-relative",on:{"submit":function($event){$event.preventDefault();return _vm.sendSubscribeNewsEmail(_vm.subscribeNewsEmail)}}},[_c('label',{attrs:{"for":""}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.subscribeNewsEmail),expression:"subscribeNewsEmail"}],attrs:{"placeholder":"Enter Email","type":"email"},domProps:{"value":(_vm.subscribeNewsEmail)},on:{"blur":function($event){return _vm.$v.subscribeNewsEmail.$touch()},"input":[function($event){if($event.target.composing){ return; }_vm.subscribeNewsEmail=$event.target.value},function($event){return _vm.$v.subscribeNewsEmail.$reset()}]}}),_c('button',{staticClass:"button",on:{"keydown":function($event){$event.preventDefault();}}},[_vm._v(" subscribe ")]),(
                _vm.$v.subscribeNewsEmail.$dirty && _vm.$v.subscribeNewsEmail.$invalid
              )?_c('div',{staticClass:"error px-3",staticStyle:{"bottom":"-20px","font-size":"12px !important","left":"20px","background-color":"rgba(68, 38, 113, .7)","border-radius":"4px"}},[_vm._v(" Incorrect email ")]):_vm._e()])]),_vm._m(2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-page__footer__item"},[_c('img',{staticClass:"main-page__footer__mob-logo",attrs:{"alt":"logo","src":require("@/assets/images/logo-beta.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',[_vm._v(" Sign Up For Aslice’s "),_c('br'),_vm._v(" Insider Newsletter: ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-page__footer__item main-page__footer__social"},[_c('div',{staticClass:"block-end"},[_c('a',{attrs:{"href":"https://www.facebook.com/asliceofficial","target":"_blanck"}},[_c('img',{attrs:{"alt":"","src":require("../assets/images/Facebook-f_Logo-Blue-Logo.svg")}})]),_c('a',{attrs:{"href":"https://twitter.com/asliceofficial","target":"_blanck"}},[_c('img',{attrs:{"alt":"","src":require("../assets/images/Twitter-Logo.wine.svg")}})]),_c('a',{attrs:{"href":"https://www.instagram.com/asliceofficial/","target":"_blanck"}},[_c('img',{attrs:{"alt":"","src":require("../assets/images/Instagram.svg")}})]),_c('a',{attrs:{"href":"https://linktr.ee/asliceofficial","target":"_blanck"}},[_c('img',{attrs:{"alt":"","src":require("../assets/images/linktree-logo-icon.svg")}})])])])}]

export { render, staticRenderFns }